<script setup lang="ts">
const props = defineProps<{
  color?: string
}>()

const backgroundColor = computed(() => {
  return props.color || '#317EA1'
})

const emit = defineEmits(['toggle-menu'])

const onMenuIconClick = () => {
  emit('toggle-menu')
}
</script>

<template>
  <nav
    :style="{ backgroundColor: backgroundColor }"
    class="flex h-16 w-full items-center justify-between shadow"
  >
    <Icon
      name="heroicons:bars-3"
      class="h-7 w-7 cursor-pointer text-white"
      @click="onMenuIconClick"
    />
    <slot></slot>
  </nav>
</template>
