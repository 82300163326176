<script lang="ts" setup>
import { FuneralCompany } from '~/entities/funeral-company'
import { FuneralCompanyResource } from '~/resources/funeral-company'

const funeralCompanyResource = new FuneralCompanyResource()
const isMenuOpen = ref<boolean>(true)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const url = useRequestURL()
const subdomain = <string>url.hostname.split('.')[0]
const funeralCompanyResponse = await funeralCompanyResource.get(subdomain)
const funeralCompany = ref<FuneralCompany>(funeralCompanyResponse.data.value.item)
</script>

<template>
  <div class="flex min-h-screen flex-col overflow-hidden transition-all duration-300 ease-in-out">
    <NavigationMenuBGO
      :class="{ 'ml-72': isMenuOpen }"
      :color="funeralCompany?.primary_color"
      class="px-8 transition-all duration-300 ease-in-out"
      @toggle-menu="toggleMenu"
    />
    <div
      :class="{ 'ml-72': isMenuOpen }"
      class="flex flex-grow transition-all duration-300 ease-in-out"
    >
      <SideMenuBGO
        :isOpen="isMenuOpen"
        :logo-src="funeralCompany?.logo_path"
        :color="funeralCompany?.primary_color"
        @close="isMenuOpen = false"
      />
      <main class="flex-grow px-8 py-5 transition-all duration-300 ease-in-out">
        <div class="max-w-screen-2xl">
          <NuxtPage :funeralCompany="funeralCompany" />
        </div>
      </main>
    </div>
  </div>
</template>

<style>
body {
  color: #333333;
}
</style>

<style scoped>
main {
  position: relative;
  z-index: 0;
}

main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('~/public/assets/bgo/bg-pattern.svg') 0 0 repeat padding-box;
  opacity: 0.06;
  z-index: -1;
}

.ml-72 {
  margin-left: 20rem;
  transition: margin-left 0.3s ease-in-out;
}

.flex {
  overflow: hidden;
}
</style>
